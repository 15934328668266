.mobile-menu-container {
	height: 100dvh;
	padding-bottom: calc(var(--g-spacing-2) + calc(var(--g-base-height-xl)));
  
	&_menu {
		position: fixed;
		z-index: 1;
		/* Set z-index higher than pseudo-element */
		bottom: 0;
		left: 0;
		right: 0;
		padding: var(--g-spacing-2);

		&::after {
			content: '';
			display: block;
			position: absolute;
			z-index: -1;
			/* Set z-index lower than parent element */
			bottom: 0;
			left: 0;
			right: 0;
			height: 64px;
			background: linear-gradient(
				to top,
				var(--g-color-base-background) 90%,
				transparent 100%
			);
		}
	}
}
