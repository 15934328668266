$padding-bottom: calc(var(--g-spacing-2) + calc(var(--g-base-height-xl)));

.loading-elements,
.grid-loading-elements {
	display: grid;
	gap: 8px;
	z-index: -1;
	position: sticky;
	height: calc(100dvh - 4.5rem);
	overflow: hidden;
}

.loading-elements {
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	gap: 8px;
}

.grid-loading-elements {
	padding-top: 0;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

@mixin grid-container($min-column-width) {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax($min-column-width, 1fr));
	gap: var(--g-spacing-2);
	width: 100%;
}

.events {
	position: relative;
	max-height: 100dvh;
	overflow: hidden;

	&_events-container {
		@include grid-container(12rem);
	}

	&_drafts-container {
		@include grid-container(10rem);
	}

	&_status {
		margin-left: var(--g-spacing-4);
		min-height: var(--g-text-caption-2-line-height);
		max-width: 112px;
	}

	&_date {
		display: flex;
		flex-direction: column;
		gap: var(--g-spacing-1);
		min-width: 112px;
		padding-left: calc(var(--g-spacing-4) + 1px); // don't ask me ask gravity
		padding-right: var(--g-spacing-2);
		margin-right: var(--g-spacing-4);
	}

	&_day {
		max-width: 30%;
		height: var(--g-text-header-1-line-height);
	}

	&_weekday {
		max-width: 90%;
		height: var(--g-text-caption-1-line-height);
	}
}

.events-with-drafts {
	max-height: calc(100dvh - $padding-bottom);
	overflow: hidden;
}

.loading-elements::after,
.grid-loading-elements::after,
.events::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to bottom,
		transparent 0%,
		var(--g-color-base-background) 80%
	);
	pointer-events: none;
}
