.outlet {
	min-width: 350px;
  width: 100%;
  padding-right: var(--g-spacing-4);
}

.menu {
	position: sticky;
  height: 100%;
	top: var(--g-spacing-4);
}

.menu-item {
	&_mobile,
	&_desktop {
		.g-button__text {
			gap: var(--g-spacing-2);
		}
	}

	&_mobile {
		justify-content: center;
	}

	&_desktop {
		justify-content: flex-start;
	}
}
