$height: calc(var(--g-spacing-4) * 8);

.gallery-images-container {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fill, minmax($height, 1fr));
	grid-auto-rows: max-content;
	gap: 0.5rem;
	grid-row-gap: 0.5rem;
	margin-right: 2rem;
}

.gallery-image-item {
	width: 100%;
	object-fit: cover;
	aspect-ratio: 1/1;
	cursor: pointer;
	border-radius: var(--g-border-radius-m);
}

.gallery-image {
	max-height: 50vh;
	max-width: 100%;
	border-radius: var(--g-border-radius-m);
}

.location-item {
	width: 100%;
	position: absolute;
	top: var(--g-spacing-2);
	left: var(--g-spacing-2);
}
