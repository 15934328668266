$height: calc(var(--g-spacing-4)*8);

.event-images-container {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fill, minmax($height, 1fr));
	grid-auto-rows: max-content;
	gap: 0.5rem;
	grid-row-gap: 0.5rem;
  margin-right: 2rem;
}

.event-image-item {
	width: 100%;
  height: 100%;
  object-fit: cover;
	aspect-ratio: 1/1;
	border-radius: var(--g-border-radius-m);
}